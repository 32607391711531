@font-face {
	font-family: Aptos;
	font-style: normal;
	font-weight: normal;
	font-display: swap;
	src: url("../fonts/Aptos.ttf");
}

@font-face {
	font-family: "Aptos Black";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url("../fonts/Aptos-Black.ttf");
}

@font-face {
	font-family: Aptos;
	font-style: normal;
	font-weight: bold;
	font-display: swap;
	src: url("../fonts/Aptos-Bold.ttf");
}

@font-face {
	font-family: Aptos;
	font-style: italic;
	font-weight: normal;
	font-display: swap;
	src: url("../fonts/Aptos-Italic.ttf");
}

@font-face {
	font-family: Aptos;
	font-style: oblique;
	font-weight: normal;
	font-display: swap;
	src: url("../fonts/Aptos-Italic.ttf");
}

@font-face {
	font-family: "Aptos SemiBold";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("../fonts/Aptos-SemiBold.ttf");
}
