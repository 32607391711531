// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use "sass:math";

@use "./assets/styles/fonts";

html {
	@include mat.theme((
		color: (
			theme-type: light,
			primary: mat.$azure-palette,
			tertiary: mat.$blue-palette,
		),
		typography: Roboto,
		density: 0,
	));
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

h1 {
	color: #e1a538;
	font-family: "Aptos Black", sans-serif;
	font-size: 36px;
	text-transform: uppercase;
}

h2 {
	color: #e1a538;
	font-family: "Aptos Black", sans-serif;
	font-size: 24px;
	text-transform: uppercase;
}

h3 {
	color: #01756d;
	font-family: "Aptos", sans-serif;
	font-size: 1.5rem;
	font-weight: bold;
}

h4 {
	font-family: "Aptos", sans-serif;
	font-size: 16px;
	font-weight: inherit;
}

span {
	font-family: Aptos, sans-serif;
	font-size: 1rem;
}

.title-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h1 {
		margin: 0;
	}

	.line {
		width: 150px;
		border: solid 1px #01766e;
		position: relative;
		left: math.random(100) + px;
	}
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
